<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <ion-item
        slot="start"
        lines="none"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal()"
      >
        <img
          src="../../assets/images/arrow-go-back-line.svg"
        >
      </ion-item>
      <ion-title class="text--white text-18">
        {{ room.roomName }}
      </ion-title>
      <ion-button
        v-if="selectedView === '1'"
        slot="end"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        :disabled="!roomName || !roomClass"
        @click="saveRoomEdit(room.id)"
      >
        <ion-label class="text--primary">
          {{ $t('save') }}
        </ion-label>
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div v-if="selectedView === '1'">
      <form
        class="form--dark"
        :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
      >
        <ion-item lines="none">
          <ion-label position="floating">
            {{ $t('Room Name') }}
          </ion-label>
          <ion-input
            v-model="roomName"
            class="capitalize"
            type="text"
            maxlength="11"
            enterkeyhint="done"
            required
            @keydown.enter="closeKeyboard"
          />
        </ion-item>
      </form>
      <ion-card
        class="mt-20 rounded-corners h-100"
        :class="roomClass"
      />
      <div class="mt-20">
        <div>
          <ion-item
            lines="none"
            color="transparent"
            detail="false"
            class="select-picture text--white"
            @click="selectedView = '2'"
          >
            <i class="material-icons picture">image</i>
          </ion-item>
          <ion-item
            lines="none"
            color="transparent"
            class="select-picture text--white"
          >
            <ion-text class="select-picture-text text--gray text-14">
              {{ $t('selectImage') }}
            </ion-text>
          </ion-item>
        </div>
      </div>
    </div>
    <div v-else-if="selectedView === '2'">
      <div
        v-for="item in backgrounds"
        :key="item"
      >
        <div
          style="height: 100px; border-radius: 10px; margin-top: 8px;"
          :class="item.bg"
          @click="roomClass = item.bg; selectedView = '1';"
        />
      </div>
    </div>
  </ion-content>
</template>

<script>
import {
  closeOutline,
  addCircleOutline,
  checkmarkDoneOutline,
  closeCircleOutline
} from 'ionicons/icons'
import { defineComponent } from 'vue'

import { Keyboard } from '@capacitor/keyboard'

export default defineComponent({
  components: {
  },
  props: {
    close: { type: Function, default: null },
    room: { type: Object, default: () => {} }
  },
  data () {
    return {
      closeOutline,
      addCircleOutline,
      checkmarkDoneOutline,
      closeCircleOutline,
      selectedView: '1',
      roomName: '',
      roomClass: '',
      backgrounds: [
        { bg: 'living-room', class: 'livin' },
        { bg: 'living-room2', class: 'livin2' },
        { bg: 'living-room3', class: 'livin3' },
        { bg: 'living-room4', class: 'livin4' },
        { bg: 'living-room5', class: 'livin5' },
        { bg: 'chair', class: 'chair' },
        { bg: 'bathroom', class: 'bathroom' },
        { bg: 'bathroom3', class: 'bathroom3' },
        { bg: 'kitchen', class: 'kitch' },
        { bg: 'kitchen2', class: 'kitch2' },
        { bg: 'kitchen3', class: 'kitch3' },
        { bg: 'sleeping-room', class: 'sleep' },
        { bg: 'bedroom4', class: 'bedroom4' },
        { bg: 'kids-room', class: 'kids' }
      ]
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    }
  },
  created () {
    this.roomName = this.room.roomName
    this.roomClass = this.room.roomClass
  },
  methods: {
    saveRoomEdit (roomId) {
      if (!this.roomName || !this.roomClass) {
        return
      }
      this.$store.dispatch('editRoom', { roomName: this.roomName.replace(/(^\w|\s\w)/g, m => m.toUpperCase()), roomClass: this.roomClass, roomId }).then(response => {
        if (response) {
          this.dismissModal()
        }
      })
    },
    dismissModal () {
      this.close()
    },
    closeKeyboard () {
      Keyboard.hide()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
ion-list {
  background-color: transparent;
}
</style>
