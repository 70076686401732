<template>
  <div class="background--modal" />
  <ion-header
    class="ion-no-border"
  >
    <ion-toolbar color="transparent">
      <ion-item
        slot="start"
        fill="clear"
        lines="none"
        color="transparent"
        class="close-modal-button"
        @click="dismissModal()"
      >
        <img src="../assets/images/arrow-go-back-line.svg">
      </ion-item>
      <ion-title class="text-18 text--white">
        {{ roomObject.roomName }}
      </ion-title>
      <ion-spinner
        v-show="gatewayProgress"
        slot="end"
        color="light"
        name="crescent"
      />
      <ion-button
        slot="end"
        fill="clear"
        color="transparent"
        size="large"
        :class="canEditCreateRooms === false ? 'disabled' : ''"
        class="close-modal-button"
        @click="roomActionSheet(roomId, roomObject)"
      >
        <i
          class="material-icons"
        >tune</i>
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <!-- Weather -->
    <ion-grid class="weather">
      <ion-row>
        <ion-col size="4">
          <div>{{ $t('inside') }}</div>
          <ion-text class="today">
            <i class="material-icons text--gray text-18">thermostat</i>
            <span>{{ roomTemperature(roomId) }}°C</span>
          </ion-text>
        </ion-col>
        <ion-col size="4">
          <div>{{ $t('Humidity') }}</div>
          <ion-text class="outside">
            <span class="material-icons text--gray text-18">opacity</span>
            <img
              style="max-width: 18px"
            >
            <span>{{ roomHumidity(roomId) }}%</span>
          </ion-text>
        </ion-col>
        <ion-col size="4">
          <div>{{ $t('devices') }}</div>
          <ion-text class="inside">
            <span class="material-icons text--gray">
              grid_view
            </span>
            <span>{{ devicesInRoom(roomId) }}</span>
          </ion-text>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid>
      <ion-row
        class="row-wrapper"
      >
        <ion-col
          v-for="device in roomDevices"
          :key="device.id"
          size="6"
          class="room-card-device-item-wrapper"
        >
          <div
            class="device-wrapper rounded-corners devices-room room-card-devices-item-active"
            :class="[device.active ? '' : 'room-card-devices-item-inactive', device.battery === 1 ? 'room-card-devices-item-inactive' : '']"
          >
            <div v-if="device.typeI === 'SensorDoorWindow'">
              <div @click="openSettingPopover(device)">
                <div
                  class="percentage text-right"
                  style="margin-right: 5px; margin-top: 5px;"
                >
                  <ion-icon
                    v-if="device.battery === 0 || device.battery === null"
                    color="medium"
                    style="font-size: 20px;"
                    :icon="batteryFullOutline"
                  />
                  <ion-icon
                    v-if="device.battery === 1"
                    color="medium"
                    style="font-size: 20px;"
                    :icon="batteryDeadOutline"
                  />
                </div>
                <div class="">
                  <img
                    class="room-icon"
                    src="../assets/devices/SensorDoorWindow.png"
                  >
                </div>
                <div class="mt-10">
                  <ion-text
                    color="transparent"
                    class="room-label mt-10 mb-10"
                  >
                    {{ device.deviceName }}
                  </ion-text>
                </div>
                <div
                  v-if="device.active"
                  class="mt-10"
                  :class="device.status === 'Open' ? 'text--primary' : 'text--gray'"
                >
                  {{ deviceStatusMapper(device.status) }}
                </div>
                <div
                  v-else
                  class="mt-10"
                >
                  {{ $t('Disconnected') }}
                </div>
              </div>
            </div>
            <div v-if="device.typeI === 'SensorMotion' || device.typeI === 'SensorMulti' || device.typeI === 'SensorVibration'">
              <div @click="openSettingPopover(device)">
                <div
                  class="percentage text-right"
                  style="margin-right: 5px; margin-top: 5px;"
                >
                  <ion-icon
                    v-if="device.battery === 0 || device.battery === null"
                    color="medium"
                    style="font-size: 20px;"
                    :icon="batteryFullOutline"
                  />
                  <ion-icon
                    v-if="device.battery === 1"
                    color="medium"
                    style="font-size: 20px;"
                    :icon="batteryDeadOutline"
                  />
                </div>
                <div class="">
                  <img
                    class="room-icon"
                    :src="require('../assets/devices/' + device.typeI + '.png')"
                  >
                </div>
                <div class="mt-10">
                  <ion-text
                    color="transparent"
                    class="room-label"
                  >
                    {{ device.deviceName }}
                  </ion-text>
                </div>
                <div
                  v-if="device.active"
                  class="mt-10"
                  :class="device.status === 'No Movement' ? 'text--gray' : 'text--primary'"
                >
                  {{ deviceStatusMapper(device.status) }}
                </div>
                <div
                  v-else
                  class="mt-10 text--primary"
                >
                  {{ $t('Disconnected') }}
                </div>
              </div>
            </div>
            <div v-if="device.typeI === 'SensorWaterLeak'">
              <div @click="openSettingPopover(device)">
                <div
                  class="percentage text-right"
                  style="margin-right: 5px; margin-top: 5px;"
                >
                  <ion-icon
                    v-if="device.battery === 0 || device.battery === null"
                    color="medium"
                    style="font-size: 20px;"
                    :icon="batteryFullOutline"
                  />
                  <ion-icon
                    v-if="device.battery === 1"
                    color="medium"
                    style="font-size: 20px;"
                    :icon="batteryDeadOutline"
                  />
                </div>
                <div class="">
                  <img
                    class="room-icon"
                    src="../assets/devices/SensorWaterLeak.png"
                  >
                </div>
                <div class="mt-10">
                  <ion-text
                    color="transparent"
                    class="room-label"
                  >
                    {{ device.deviceName }}
                  </ion-text>
                </div>
                <div
                  v-if="device.active"
                  class="mt-10"
                  :class="device.status === 'Water Leak Detected!' ? 'text--primary' : 'text--gray'"
                >
                  {{ deviceStatusMapper(device.status) }}
                </div>
                <div
                  v-else
                  class="mt-10 text--primary"
                >
                  {{ $t('Disconnected') }}
                </div>
              </div>
            </div>
            <div v-if="device.typeI === 'SensorSmoke'">
              <div @click="openSettingPopover(device)">
                <div
                  class="percentage text-right"
                  style="margin-right: 5px; margin-top: 5px;"
                >
                  <ion-icon
                    v-if="device.battery === 0 || device.battery === null"
                    color="medium"
                    style="font-size: 20px;"
                    :icon="batteryFullOutline"
                  />
                  <ion-icon
                    v-if="device.battery === 1"
                    color="medium"
                    style="font-size: 20px;"
                    :icon="batteryDeadOutline"
                  />
                </div>
                <div class="">
                  <img
                    class="room-icon"
                    src="../assets/devices/SensorSmoke.png"
                  >
                </div>
                <div class="mt-10">
                  <ion-text
                    color="transparent"
                    class="room-label"
                  >
                    {{ device.deviceName }}
                  </ion-text>
                </div>
                <div
                  v-if="device.active"
                  class="mt-10"
                  :class="device.status === 'No Smoke Detected' ? 'text--gray' : 'text--primary'"
                >
                  {{ deviceStatusMapper(device.status) }}
                </div>
                <div
                  v-else
                  class="mt-10 text--primary"
                >
                  {{ $t('Disconnected') }}
                </div>
              </div>
            </div>
            <div v-if="device.typeI === 'SmartLightColorDimmable'">
              <div @click="openSettingPopover(device)">
                <div class="percentage text-right">
                  <span class="text--gray" />
                </div>
                <div class="">
                  <img
                    class="room-icon"
                    src="../assets/devices/SmartLightColorDimmable.png"
                  >
                </div>
                <div class="mt-10">
                  <ion-text
                    color="transparent"
                    class="room-label"
                  >
                    {{ device.deviceName }}
                  </ion-text>
                </div>
              </div>
              <div
                v-if="device.active"
                class="mt-10"
              >
                <ion-toggle
                  :disabled="$store.state.communication"
                  :value="device.switchgear === 'on' ? false : true"
                  :checked="device.switchgear === 'on' ? true : false"
                  @click="controlTunableDevice($event.target.value, device)"
                />
              </div>
              <div
                v-else
                class="mt-10 text--primary"
              >
                {{ $t('Disconnected') }}
              </div>
              <!--<ion-text
                text-wrap
                color="transparent"
                class="room-status"
              >
                {{ Math.round((device.lightLevel / 255)*100) }}% / {{ Math.round(1000000 / device.colorTemp) }}K
              </ion-text>-->
              <!-- <ion-text
                text-wrap
                color="transparent"
                class="room-toggle"
              >
                {{ device.switchgear === 'off' ? 'Off' : 'On' }}
              </ion-text>-->
            </div>
            <div v-if="device.typeI === 'SmartLightColorChangeable'">
              <div @click="openSettingPopover(device)">
                <div class="percentage text-right">
                  <span class="text--gray" />
                </div>
                <div class="">
                  <img
                    class="room-icon"
                    src="../assets/devices/SmartLightColorChangeable.png"
                  >
                </div>
                <div class="mt-10">
                  <ion-text
                    color="transparent"
                    class="room-label"
                  >
                    {{ device.deviceName }}
                  </ion-text>
                </div>
              </div>
              <div
                v-if="device.active"
                class="mt-10"
              >
                <ion-toggle
                  :disabled="$store.state.communication"
                  :value="device.switchgear === 'on' ? false : true"
                  :checked="device.switchgear === 'on' ? true : false"
                  @click="controlTunableDevice($event.target.value, device)"
                />
              </div>
              <div
                v-else
                class="mt-10 text--primary"
              >
                {{ $t('Disconnected') }}
              </div>
              <!--<ion-text
                text-wrap
                color="transparent"
                class="room-status"
              >
                {{ Math.round((device.lightLevel / 255)*100) }}% / {{ Math.round(1000000 / device.colorTemp) }}K
              </ion-text>-->
              <!-- <ion-text
                text-wrap
                color="transparent"
                class="room-toggle"
              >
                {{ device.switchgear === 'off' ? 'Off' : 'On' }}
              </ion-text>-->
            </div>
            <div v-if="device.typeI === 'WallSwitch'">
              <div @click="openSettingPopover(device)">
                <div class="percentage text-right">
                <!-- <span class="text--white">76%</span> -->
                </div>
                <div class="">
                  <img
                    class="room-icon"
                    src="../assets/devices/WallSwitch.png"
                  >
                </div>
                <div class="mt-10">
                  <ion-text
                    color="transparent"
                    class="room-label"
                  >
                    {{ device.deviceName }}
                  </ion-text>
                </div>
              </div>
              <div
                v-if="device.active"
                class="mt-10"
              >
                <ion-toggle
                  :disabled="$store.state.communication"
                  :value="device.switchgear === 'on' ? false : true"
                  :checked="device.switchgear === 'on' ? true : false"
                  @click="controlTunableDevice($event.target.value, device)"
                />
              </div>
              <div
                v-else
                class="mt-10 text--primary"
              >
                {{ $t('Disconnected') }}
              </div>
            </div>
            <div v-if="device.typeI === 'SmartPlug'">
              <div @click="openSettingPopover(device)">
                <div class="percentage text-right">
                  <span class="text--white" />
                </div>
                <div class="">
                  <img
                    class="room-icon"
                    src="../assets/devices/SmartPlug.png"
                  >
                </div>
                <div class="mt-10">
                  <ion-text
                    color="transparent"
                    class="room-label"
                  >
                    {{ device.deviceName }}
                  </ion-text>
                </div>
              </div>
              <div
                v-if="device.active"
                class="mt-10"
              >
                <ion-toggle
                  :disabled="$store.state.communication"
                  :value="device.switchgear === 'on' ? false : true"
                  :checked="device.switchgear === 'on' ? true : false"
                  @click="controlTunableDevice($event.target.value, device)"
                />
              </div>
              <div
                v-else
                class="mt-10 text--primary"
              >
                {{ $t('Disconnected') }}
              </div>
            </div>
            <div v-if="device.typeI === 'IASWarning'">
              <div @click="openSettingPopover(device)">
                <div
                  class="percentage text-right"
                  style="margin-right: 5px; margin-top: 5px;"
                >
                  <!-- <ion-icon
                    v-if="device.battery === 0"
                    color="medium"
                    style="font-size: 20px;"
                    :icon="batteryFullOutline"
                  /> -->
                  <!-- <ion-icon
                    v-if="device.battery === 1"
                    color="medium"
                    style="font-size: 20px;"
                    :icon="batteryDeadOutline"
                  /> -->
                </div>
                <div class="">
                  <img
                    class="room-icon"
                    src="../assets/devices/IASWarning.png"
                  >
                </div>
                <div class="mt-10">
                  <ion-text
                    color="transparent"
                    class="room-label"
                  >
                    {{ device.deviceName }}
                  </ion-text>
                </div>
              </div>
              <div
                v-if="device.active"
                class="mt-10"
                :class="device.status === 'inactive' ? 'text--gray' : 'text--primary'"
              >
                {{ deviceStatusMapper(device.status) }}
              </div>
              <div
                v-else
                class="mt-10 text--primary"
              >
                {{ $t('Disconnected') }}
              </div>
            </div>
            <div v-if="device.typeI === 'SensorTemperatureOutdoor'">
              <div @click="openSettingPopover(device)">
                <div
                  class="percentage text-right"
                  style="margin-right: 5px; margin-top: 5px;"
                >
                  <ion-icon
                    v-if="device.battery === 0 || device.battery === null"
                    color="medium"
                    style="font-size: 20px;"
                    :icon="batteryFullOutline"
                  />
                  <ion-icon
                    v-if="device.battery === 1"
                    color="medium"
                    style="font-size: 20px;"
                    :icon="batteryDeadOutline"
                  />
                </div>
                <div class="">
                  <img
                    class="room-icon"
                    src="../assets/devices/SensorTemperatureOutdoor.png"
                  >
                </div>
                <div class="mt-10">
                  <ion-text
                    color="transparent"
                    class="room-label"
                  >
                    {{ device.deviceName }}
                  </ion-text>
                </div>
                <div
                  v-if="device.active"
                  class="mt-10 text--gray"
                >
                  {{ Math.round(device.temp/100) }}°C / {{ Math.round(device.humi/100) }}%
                </div>
                <div
                  v-else
                  class="mt-10 text--primary"
                >
                  {{ $t('Disconnected') }}
                </div>
              </div>
            </div>
            <div v-if="device.typeI === 'SensorTemperatureIndoor'">
              <div @click="openSettingPopover(device)">
                <div
                  class="percentage text-right"
                  style="margin-right: 5px; margin-top: 5px;"
                >
                  <ion-icon
                    v-if="device.battery === 0 || device.battery === null"
                    color="medium"
                    style="font-size: 20px;"
                    :icon="batteryFullOutline"
                  />
                  <ion-icon
                    v-if="device.battery === 1"
                    color="medium"
                    style="font-size: 20px;"
                    :icon="batteryDeadOutline"
                  />
                </div>
                <div class="">
                  <img
                    class="room-icon"
                    src="../assets/devices/SensorTemperatureIndoor.png"
                  >
                </div>
                <div class="mt-10">
                  <ion-text
                    color="transparent"
                    class="room-label"
                  >
                    {{ device.deviceName }}
                  </ion-text>
                </div>
                <div
                  v-if="device.active"
                  class="mt-10 text--gray"
                >
                  {{ Math.round(device.temp/100) }}°C / {{ Math.round(device.humi/100) }}%
                </div>
                <div
                  v-else
                  class="mt-10 text--primary"
                >
                  {{ $t('Disconnected') }}
                </div>
              </div>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
  <ion-fab
    slot="fixed"
    vertical="bottom"
    horizontal="end"
  >
    <ion-fab-button @click="openAddDeviceModal(roomId)">
      <ion-icon
        color="dark"
        :icon="addOutline"
      />
    </ion-fab-button>
  </ion-fab>
</template>

<script>
import {
  chevronBackOutline,
  add,
  addOutline,
  thermometerOutline,
  bulbOutline,
  waterOutline,
  checkmarkDoneOutline,
  warningOutline,
  settingsOutline,
  videocamOutline,
  tabletPortraitOutline,
  flameOutline,
  toggleOutline,
  batteryFullOutline,
  batteryDeadOutline
} from 'ionicons/icons'
import { defineComponent } from 'vue'

import { modalController, popoverController, actionSheetController, alertController } from '@ionic/vue'

import SettingPopOver from '../components/Popovers/SettingPopOver'
import addDeviceModal from '../components/AddDeviceModal'
import editRoomModal from '../components/Rooms/EditRoomModal'

export default defineComponent({
  name: 'RoomModal',
  props: {
    modalTitle: { type: String, default: 'Modal' },
    roomId: { type: Number, default: null },
    roomObject: { type: Object, default: () => {} },
    close: { type: Function, default: null }
  },
  data () {
    return {
      chevronBackOutline,
      add,
      addOutline,
      thermometerOutline,
      bulbOutline,
      waterOutline,
      checkmarkDoneOutline,
      warningOutline,
      settingsOutline,
      videocamOutline,
      tabletPortraitOutline,
      flameOutline,
      batteryFullOutline,
      batteryDeadOutline,
      toggleOutline,
      detailOpen: true,
      togLight: true,
      togHeater: true,
      togLeak: false,
      canEditCreateRooms: this.$store.state.userData.length ? this.$store.state.userData[0].userCanEditCreateRooms : undefined
    }
  },
  computed: {
    roomDevices () {
      const propertyDevices = this.$store.state.propertyDevices
      const devices = propertyDevices.filter(obj => obj.propertyRoomId === this.roomId)
      return devices
    },
    propertyRooms () {
      return this.$store.state.propertyRooms
    },
    gatewayProgress () {
      return this.$store.state.gatewayProgress
    }
  },
  methods: {
    async roomActionSheet (roomId, room) {
      const actionSheet = await actionSheetController
        .create({
          cssClass: 'my-custom-class',
          buttons: [
            {
              text: this.$t('Edit Room'),
              handler: () => {
                this.openEditRoomModal(room)
              }
            },
            {
              text: this.$t('Delete Room'),
              role: 'destructive',
              handler: () => {
                this.deleteRoom(roomId)
              }
            },
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              handler: () => {
                console.log('Cancel clicked')
              }
            }
          ]
        })
      await actionSheet.present()

      const { role } = await actionSheet.onDidDismiss()
      console.log('onDidDismiss resolved with role', role)
    },
    deleteRoom (id) {
      if (this.roomDevices.length) {
        this.removeAlertContainsDevices()
      } else if (this.propertyRooms.lenght === 1) {
        this.removeAlertConfirm()
      } else {
        this.$store.dispatch('deleteRoom', id).then(response => {
          this.dismissModal()
        })
      }
    },
    devicesInRoom (roomId) {
      const devices = this.$store.state.propertyDevices
      let count = 0
      for (let i = 0; i < devices.length; ++i) {
        if (devices[i].propertyRoomId === roomId) {
          count++
        }
      }
      return count
    },
    deviceStatusMapper (status) {
      let statusResult = ''
      if (status === 'Initializing...') {
        statusResult = 'Initializing...'
      } else if (status === 'Open') {
        statusResult = this.$t('sensorDoorWindowOpen')
      } else if (status === 'Closed') {
        statusResult = this.$t('sensorDoorWindowClose')
      } else if (status === 'No Movement') {
        statusResult = this.$t('sensorMotionNoMovement')
      } else if (status === 'Movement Detected') {
        statusResult = this.$t('sensorMotionMovement')
      } else if (status === 'No Smoke Detected') {
        statusResult = this.$t('sensorSmokeNoSmokeDetected')
      } else if (status === 'Smoke Detected') {
        statusResult = this.$t('sensorSmokeSmokeDetected')
      } else if (status === 'No Water Leak Detected') {
        statusResult = this.$t('sensorWaterLeakNoLeakDetected')
      } else if (status === 'Water Leak Detected!') {
        statusResult = this.$t('sensorWaterLeakLeakDetected')
      } else if (status === 'active') {
        statusResult = this.$t('smartSirenStatusActive')
      } else if (status === 'inactive') {
        statusResult = this.$t('smartSirenStatusSilent')
      }
      return statusResult
    },
    roomTemperature (roomId) {
      const devices = this.$store.state.propertyDevices
      if (!roomId) {
        let count = 0
        let temperature = 0
        devices.forEach(item => {
          if (item.temp && item.typeI !== 'SensorTemperatureOutdoor') {
            count++
            temperature = temperature + item.temp
          }
        })
        return Math.round((temperature / count) / 100)
      } else {
        const found = devices.find(element => element.propertyRoomId === roomId && element.temp !== null && element.typeI !== 'SensorTemperatureOutdoor')
        const temp = found ? Math.round(found.temp / 100) : ''
        return temp
      }
    },
    roomHumidity (roomId) {
      const devices = this.$store.state.propertyDevices
      const found = devices.find(element => element.propertyRoomId === roomId && element.humi !== null && element.typeI !== 'SensorTemperatureOutdoor')
      const temp = found ? Math.round(found.humi / 100) : ''
      return temp
    },
    async openSettingPopover (device) {
      const popover = await popoverController
        .create({
          component: SettingPopOver,
          cssClass: 'popover',
          translucent: false,
          componentProps: {
            device,
            close: () => popover.dismiss()
          }
        })
      await popover.present()
    },
    controlTunableDevice (value, device) {
      let toggle
      if (value === 'true') {
        toggle = 'on'
      } else {
        toggle = 'off'
      }
      this.$store.dispatch('lightControl', { type: 'switchgear', value: toggle, ieee: device.ieee, ep: device.ep })
        .then(response => {
          if (response) {
            this.$store.commit('communication', false)
          }
        })
    },
    openAddDeviceModal (roomId) {
      return modalController
        .create({
          component: addDeviceModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            roomId: roomId,
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    openEditRoomModal (roomObject) {
      return modalController
        .create({
          component: editRoomModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            room: roomObject,
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    async removeAlertConfirm () {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('Sorry'),
          message: this.$t('Cannot be removed as this room is last in you home'),
          buttons: [
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
                console.log('Confirm Cancel:', blah)
              }
            }
          ]
        })
      return alert.present()
    },
    async removeAlertContainsDevices () {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('Sorry'),
          message: this.$t('Cannot be removed as room contains active devices'),
          buttons: [
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
                console.log('Confirm Cancel:', blah)
              }
            }
          ]
        })
      return alert.present()
    },
    dismissModal () {
      this.close()
    },
    closeModal () {
      modalController.dismiss()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import '~@/theme/_layouts';
@import '~@/theme/_backgrounds';

.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.close-room-modal-button {
  height: 60px;
  width: 110px;
}

.close-room-modal-button-icon {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 8px 12px 7px 10px;
  border-radius: $radius-sm;
}

.room-card-header {
  justify-content: flex-end;
  display: block;
  border: 0;
  border-top: 3px solid rgba(165, 165, 165, 0.315);
  margin: 0px 160px 5px 160px;
  padding: 0;
  border-radius: 99px;
}
.room-card-devices-stat {
  font-size: 20px;
  margin-bottom: 15px;
}
.room-card-add-device-button {
  height: 45px;
  width: 110px;
}
.room-card-add-device-button-icon {
  margin-right: 15px;
  padding: 5px 6px 5px 6px;
  border-radius: $radius-sm;
}
.row-wrapper {
  min-height: 100%;
}
.room-card-device-item-wrapper {
  padding: 7px;
}
.room-card-devices-item {
  min-height: 180px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.308);
}
.room-card-devices-item-active {
  @include bg-box-room-device;
}
.room-card-devices-item-inactive {
  border: 1px solid red;
}
.room-toggle {
    position: absolute;
    color: white;
    right: 18px;
    top: 8%;
    font-size: 12px;
}
.room-icon-ok {
  position: absolute;
  color: greenyellow;
  right: 30px;
  top: 25%;
  font-size: 45px;
}
.room-icon-warning {
  position: absolute;
  color: red;
  right: 30px;
  top: 25%;
  font-size: 45px;
}
.room-icon {
  /*position: absolute;*/
    font-size: 45px;
    width: 60px;
    margin: auto;
    display: block;
    margin-top: 10px;
    color: #fff;
}
.room-label {
  /*position: absolute;*/
  color: white;
  left: 35px;
  top: 60%;
  font-size: 16px;
  font-weight: bolder;
}
.room-value {
 /* position: absolute;*/
  color: white;
  right: 35px;
  top: 60%;
  font-size: 16px;
}
.room-status {
 /* position: absolute;*/
  color: white;
  left: 35px;
  top: 75%;
  font-size: 16px;
}
ion-fab {
 bottom: 30px;
}
.weather {
    margin: 0 auto;
    width: 280px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    padding-top:20px;
    ion-row {
      ion-col {
        div {
          color: rgba(217,225,217,0.5);
          text-transform: uppercase;
          font-size: 10px;
          line-height: 11.93px;
          letter-spacing: 1px;
          font-weight: 400;
          margin-bottom: 5px;
        }
        ion-text {
          // font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          letter-spacing: 0.5px;
          display: flex;
          align-items: center;
          justify-content: center;
          ion-icon {
            color: rgba(217,225,217,0.5);
          }
          span {
            position: relative;
            padding-left: 5px;
            font-size: 15px;
          }
        }
    }
  }
}
</style>
